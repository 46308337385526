<template>
  <div class="analyse-cards container">
    <div v-if="!!errorMessage" class="notification is-warning">
      {{ errorMessage }}
    </div>
    <div
      class="file is-inline-flex is-primary"
      data-testid="button-choose"
    >
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          name="resume"
          ref="fileInputRef"
          v-on:change="onFileInputChange"
        >
        <span class="file-cta">
          <span class="file-icon">
            <font-awesome-icon icon="upload" />
          </span>
          <span class="file-label">
            Choose a file…
          </span>
        </span>
      </label>
    </div>

    <section v-if="analysedCards.length > 0">
      <h2 class="title is-2">Cards</h2>
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Front</th>
            <th>Back</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(card, index) in analysedCards" v-bind:key="index">
            <td>
              {{ index }}
            </td>
            <td class="front">
              {{ card.front }}
            </td>
            <td class="back">
              <span v-for="(word, index) in card.analysedWords" v-bind:key="index"
                  :class="{
                    ignored: word.type === WordType.Ignored,
                    c3000: word.type === WordType.Oxford3000,
                    c5000: word.type === WordType.Oxford5000,
                    irregular: word.type === WordType.Irregular,
                    hard: word.type === WordType.NotFound
                  }">{{ word.word+' ' }}</span>
            </td>
            <td :class="[card.in3000 === 0 ? 'zero-percent' : 'c3000']">
              {{ card.in3000 }}%
            </td>
            <td :class="[card.in5000 === 0 ? 'zero-percent' : 'c5000']">
              {{ card.in5000 }}%
            </td>
            <td :class="[card.rest === 0 ? 'zero-percent' : 'hard']">
              {{ card.rest }}%
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AnalysedCard, WordType, analyseSentenceApiService } from '../services/analyseSentence.api.service';

export default class AnalyseCards extends Vue {
  @Prop({ isRequired: true }) packId!: number;

  WordType = WordType;

  analysedCards: AnalysedCard[] = [];

  file: File | null = null;

  errorMessage = '';

  getFile(): File | null {
    const fileInput = this.$refs.fileInputRef as HTMLInputElement;
    if (!fileInput.files || !fileInput.files[0]) {
      return null;
    }
    return fileInput.files[0];
  }

  private analyseFile(file: File): Promise<AnalysedCard[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        if (e?.target?.result && typeof e.target.result === 'string') {
          try {
            const parsed = await analyseSentenceApiService.analyseSentences(e.target.result);
            resolve(parsed);
          } catch {
            reject(new Error('Parse error'));
          }
        }
        reject(new Error('No content'));
      };

      reader.readAsText(file);
    });
  }

  async onFileInputChange(): Promise<void> {
    this.file = this.getFile();
    if (this.file) {
      this.analysedCards = [];
      try {
        this.analysedCards = await this.analyseFile(this.file);
        console.log(this.analysedCards[42]);
      } catch (e) {
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        this.file = null;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .ignored {
    color: rgb(220, 220, 220);
  }
  .zero-percent {
    color: rgb(220, 220, 220);
  }
  .c3000 {
    color: rgb(0, 200, 200);
  }
  .c5000 {
    color: rgb(0, 100, 200);
  }
  .hard {
    color: rgb(255, 100, 100);
  }
  .irregular {
    color: rgb(200, 130, 200);
  }
/*
  .front {
    width: 40%;
  }
  .back {
    width: 40%;
  }
  */
</style>
