import http from '@/http-common';

export interface GatheredWord {
  id: string;
  word: string;
  count: number;
}

export class GatheredWordService {
  private compareWords(a: GatheredWord, b: GatheredWord) {
    if (a.word > b.word) {
      return 1;
    }
    if (a.word < b.word) {
      return -1;
    }

    return 0;
  }
  /*
  async create(projectId: string, words: string[]): Promise<string[]> {
    const result = await http.post(`${endpointPath}/${projectId}`, words);
    return result.data;
  }
  */

  async getAll(projectId: string): Promise<GatheredWord[]> {
    const result = await http.get(`/projects/${projectId}/gathered-words`);
    return result.data.sort(this.compareWords);
  }
  /*
  async delete(word: string): Promise<void> {
    return http.delete(`/words/${word}`);
  }
  */
}

export const gatheredWordService = new GatheredWordService();
