import http from '@/http-common';

const endpointPath = '/gathered-sentences';

interface GatheredSentence {
  id: string;
  projectId: string;
  sentence: string;
}

export class SentenceService {
  async getAll(projectId: string): Promise<GatheredSentence[]> {
    const result = await http.get(`${endpointPath}/${projectId}`);
    return result.data;
  }

  async delete(id: number): Promise<void> {
    return http.delete(`${endpointPath}/${id}`);
  }
}

export const sentenceService = new SentenceService();
