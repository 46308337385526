
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AnalysedCard, WordType, analyseSentenceApiService } from '../services/analyseSentence.api.service';

export default class AnalyseCards extends Vue {
  @Prop({ isRequired: true }) packId!: number;

  WordType = WordType;

  analysedCards: AnalysedCard[] = [];

  file: File | null = null;

  errorMessage = '';

  getFile(): File | null {
    const fileInput = this.$refs.fileInputRef as HTMLInputElement;
    if (!fileInput.files || !fileInput.files[0]) {
      return null;
    }
    return fileInput.files[0];
  }

  private analyseFile(file: File): Promise<AnalysedCard[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        if (e?.target?.result && typeof e.target.result === 'string') {
          try {
            const parsed = await analyseSentenceApiService.analyseSentences(e.target.result);
            resolve(parsed);
          } catch {
            reject(new Error('Parse error'));
          }
        }
        reject(new Error('No content'));
      };

      reader.readAsText(file);
    });
  }

  async onFileInputChange(): Promise<void> {
    this.file = this.getFile();
    if (this.file) {
      this.analysedCards = [];
      try {
        this.analysedCards = await this.analyseFile(this.file);
        console.log(this.analysedCards[42]);
      } catch (e) {
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        this.file = null;
      }
    }
  }
}
