import http from '@/http-common';

export interface Card {
  front: string;
  back: string;
}

export enum WordType {
  Oxford3000,
  Oxford5000,
  NotFound,
  Ignored,
  Irregular,
}

export interface AnalysedWord {
  word: string;
  type: WordType;
}

export interface AnalysedCard extends Card {
  analysedWords: AnalysedWord[];
  in3000: number;
  in5000: number;
  rest: number;
}

export class AnalyseSentenceApiService {
  async analyseSentences(txtOrTsv: string): Promise<AnalysedCard[]> {
    const txt = txtOrTsv.replaceAll('\t', '\n');
    const result = await http.post('/analyse-sentences', { txt });
    return result.data;
  }
}

export const analyseSentenceApiService = new AnalyseSentenceApiService();
