
import { Options, Vue } from 'vue-class-component';
import { gatheredWordService } from '@/services/gatheredWord.service';
import { sentenceService } from '@/services/sentence.service';
import EzProjectSelector from '@/components/projectSelector.vue';
import { useSelectStore } from '@/stores/select.store';

interface Sentence {
  id: string;
  sentence: string;
  words: string[];
}

@Options({
  components: {
    EzProjectSelector,
  },
})
export default class SelectPage extends Vue {
  words: string[] = [];

  sentences: Sentence[] | null = null;

  matchedSentences = new Set<Sentence>();

  initialised = false;

  selectStore = useSelectStore();

  private getWordsOfSentence(sentence: string): string[] {
    const noSpecialCharacters = (str: string) => str.replace(/[^a-zA-Z0-9'\- ]/g, '');
    const words = sentence.split(/[\s/&—]/);
    const strippedWords = words.map((word) => noSpecialCharacters(word));
    return strippedWords;
  }

  private sentenceHasSelectedWords(sentence: Sentence) {
    const hasSelectedWords = sentence.words.reduce(
      (result, word) => result || this.selectStore.wordIsSelected(word),
      false,
    );
    return hasSelectedWords;
  }

  private setHeightTo100() {
    const element = this.$refs.wordsAndSentences as HTMLElement;
    if (element) {
      element.style.height = `${window.innerHeight - element.offsetTop}px`;
    }
  }

  private findSentences(): void {
    if (this.sentences === null) {
      return;
    }
    this.matchedSentences.clear();
    this.sentences.forEach((sentence) => {
      if (this.sentenceHasSelectedWords(sentence)) {
        this.matchedSentences.add(sentence);
      }
    });
  }

  created(): void {
    this.fetchApi();
  }

  mounted(): void {
    window.addEventListener('resize', this.setHeightTo100);

    this.setHeightTo100();
  }

  unmounted(): void {
    window.removeEventListener('resize', this.setHeightTo100);

    this.setHeightTo100();
  }

  onProjectChange(projectId: string): void {
    // this.$router.push(`/select/${projectId}`);
    this.selectStore.setProject(projectId);
    this.fetchApi();
  }

  onSentenceClick(sentence: Sentence): void {
    if (this.selectStore.cardIsCreated(sentence.id)) {
      this.selectStore.removeCard(sentence.id);
    } else {
      this.selectStore.createCard(sentence.id, sentence.sentence);
    }
  }

  onWordClick(word: string): void {
    if (this.selectStore.wordIsSelected(word)) {
      this.selectStore.removeSelectedWord(word);
    } else {
      this.selectStore.addSelectedWord(word);
    }
    this.findSentences();
  }

  onClickWordDone(word: string): void {
    if (this.selectStore.wordIsDone(word)) {
      this.selectStore.removeDoneWord(word);
    } else {
      this.selectStore.addDoneWord(word);
    }
    this.findSentences();
  }

  async fetchApi(): Promise<void> {
    if (this.selectStore.currentProject === '') {
      return;
    }

    this.words = (await gatheredWordService.getAll(this.selectStore.currentProject))
      .map((gatheredWord) => gatheredWord.word);
    this.sentences = (await sentenceService.getAll(this.selectStore.currentProject))
      .map((sentence) => ({ ...sentence, words: this.getWordsOfSentence(sentence.sentence) }));
    this.initialised = true;
  }
}
