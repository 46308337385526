import { defineStore } from 'pinia';

export type SentenceId = string;

export interface Card {
  sentenceId: string;
  sentence: string;
  translation: string;
}

interface Project {
  selectedWords: string[];
  doneWords: string[];
  cards: Card[];
}

export interface SentenceStore {
    projects: Record<string, Project>;
    currentProject: string;
}

export const useSelectStore = defineStore({
  id: 'sentence',
  state: () => ({
    projects: {} as Record<string, Project>,
    currentProject: '' as string,
  }),
  // persist: true,
  getters: {
    projectsSelectedWords: (state) => (
      state.currentProject
        ? state.projects[state.currentProject].selectedWords
        : []
    ),
    wordIsSelected() {
      return (word: string) => this.projectsSelectedWords.includes(word);
    },
    projectsDoneWords: (state) => (
      state.currentProject
        ? state.projects[state.currentProject].doneWords
        : []
    ),
    wordIsDone() {
      return (word: string) => this.projectsDoneWords.includes(word);
    },
    projectsCards: (state) => (
      state.currentProject
        ? state.projects[state.currentProject].cards
        : []
    ),
    projectsCard() {
      return (sentenceId: string) => (
        this.projectsCards.find((card) => card.sentenceId === sentenceId)
      );
    },
    cardIsCreated() {
      return (sentenceId: string) => (
        !!this.projectsCards.find((item) => item.sentenceId === sentenceId)
      );
    },
  },
  actions: {
    setProject(id: string) {
      this.currentProject = id;
      if (!this.projects[this.currentProject]) {
        this.projects[this.currentProject] = {
          selectedWords: [],
          doneWords: [],
          cards: [],
        };
      }
    },
    addSelectedWord(word: string) {
      if (!this.currentProject) {
        return;
      }
      this.projectsSelectedWords.push(word);
    },
    removeSelectedWord(word: string) {
      if (!this.currentProject) {
        return;
      }
      const index = this.projectsSelectedWords.indexOf(word);
      this.projectsSelectedWords.splice(index, 1);
    },
    addDoneWord(word: string) {
      if (!this.currentProject) {
        return;
      }
      this.projectsDoneWords.push(word);
    },
    removeDoneWord(word: string) {
      if (!this.currentProject) {
        return;
      }
      const index = this.projectsDoneWords.indexOf(word);
      this.projectsDoneWords.splice(index, 1);
    },
    createCard(sentenceId: SentenceId, sentence: string) {
      if (!this.currentProject) {
        return;
      }
      this.projects[this.currentProject].cards.push({
        sentenceId,
        sentence,
        translation: '',
      });
    },
    removeCard(sentenceId: SentenceId) {
      if (!this.currentProject) {
        return;
      }
      const index = this.projectsCards.findIndex((item) => item.sentenceId === sentenceId);
      if (!index) {
        return;
      }
      this.projectsCards.splice(index, 1);
    },
    updateCard(sentenceId: SentenceId, newContent: Card): void {
      const card = this.projectsCards.find((item) => item.sentenceId === sentenceId);
      if (!card) {
        return;
      }
      card.sentence = newContent.sentence;
      card.translation = newContent.translation;
    },
  },
});

/*
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSelectStore, import.meta.hot));
}
*/
