
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Project, projectService } from '@/services/project.service';

@Options({
  emits: ['selected'],
})
export default class EzProjectSelector extends Vue {
  @Prop({ isRequired: false }) initialValue?: string;

  projectId: string | undefined = undefined;

  projects: Project[] | null = null;

  initialised = false;

  async created(): Promise<void> {
    this.projects = await projectService.getImportedProjects();
    this.projectId = this.initialValue;
    this.initialised = true;
  }

  onProjectChange(event: any): void {
    this.$emit('selected', event.target.value);
  }
}
